@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
body {
    background-color: #f5f5f5;
    font-family: 'Montserrat', sans-serif !important;
    color: #505050 !important;
}
.MuiTypography-root{
    font-family: 'Montserrat', sans-serif !important;
    color: #505050 !important;
}
a {
    outline: none;
    color: #505050;
}
a:hover {
    text-decoration: none;
    color: #333333;
}
.bg-primary {
    background-color: 
    #0088C8 !important;
}
#remove-application-button {
    background-color: 
    #0099E2 !important;
    color: white !important;
    margin-left: 10px !important;
}
#secondary-btn {
    background-color: 
    #eeeeee !important;
}
.nav-link {
    cursor: pointer;
}
#cancel-remove-application-button {
    background-color: 
    #eeeeee !important;
    margin-left: 10px !important;
}
#add-domain-button {
    background-color: 
    #0099E2 !important;
    color: white !important;
    margin-left: 10px !important;
}
#cancel-add-domain-button {
    background-color: 
    #eeeeee !important;
    margin-left: 10px !important;
}
.dropdown-item.active, .dropdown-item:active {
    color: 
#fff;
text-decoration: none;
background-color:
    #0099E2;
}
.MuiButton-containedPrimary {
    background-color: 
    #0099E2 !important;
}
.MuiButton-containedPrimary:hover {
    background-color: 
    #0088C8 !important;
}
.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
    padding-right: 15px;
    padding-left: 15px;
}
.MuiTypography-root{
    color: #505050;
}
div {
    color: #505050;
}
.remove-x {
    font-size: 12px;
    padding-left: 10px;
    cursor: pointer;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #0088C8 !important;
}
.MuiLinearProgress-colorPrimary {
    background-color: #ffffff !important;
}
/* FORM & INPUTS */
.MuiInput-underline::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid 
    #0099E2 !important;
    pointer-events: none;
}
.MuiInput-input{
    font-size: 14px !important;
}
.Mui-focused{
    color: #0099E2 !important;
}
.MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid #747474 !important;
}
.MuiInput-input:focus {
    color: black;
}
.MuiTypography-body1{
    font-size: 12px !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
    color: 
    #0099E2 !important;
}
button, input[type=checkbox] {
    overflow: hidden;
    outline: none;
}
/* END FORM & INPUTS */

/* Sweetalert */
.swal2-popup {
    padding: 30px 0px;
}
.swal2-confirm{
    background-color:
    #0099E2 !important;
    outline: none !important;
}
.swal2-confirm:hover{
    background-color:
    #0099E2 !important;
    outline: none !important;
}
.swal2-confirm:focus{
    background-color:
    #0099E2 !important;
    outline: none !important;
}
/* END Sweetalert */
/* Application View */
.application-view-title{
    font-size: 18px;
}
.application-view-url{
    font-size: 13px;
}
.application-view-resources{
    font-size: 12px;
}
.sc-pill{
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 4px 8px;
}
.sc-size-label label {
    padding-left: 10px;
    font-size: 15px;
}
.sc-size-info{
    font-size: 14px;
}
.sc-pill-mini{
    font-size: 9.5px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 3px 10px;
}
.sc-save-button {
    font-size: 11px !important;
    padding: 6px 8px !important;
    min-width: 0px !important;
    background-color: #eee !important;
}
.sc-save-button:hover {
    font-size: 11px !important;
    padding: 6px 8px !important;
    min-width: 0px !important;
    background-color: #eee !important;
}
.sc-save-button:focus {
    font-size: 11px !important;
    padding: 6px 8px !important;
    min-width: 0px !important;
    background-color: #eee !important;
}
.dropdown-item{
    font-size: 13.5px;
}
button {
    outline: none !important;
}
/* .sc-size-card{
    box-shadow: 0 0 5px 3px #f5f5f5 !important;
} */
.sc-size-card{
    border: 1px solid #f7f7f7 !important;
    box-shadow: 0 0 1px 1px #f5f5f5 !important;
}
.sc-footer {
    background-color: #0099E2;
    padding: 13px 0px;
}
.sc-copyright {
    font-size: 11px;
    text-transform: uppercase;
    color: white;
}
.sc-footer-links {
    font-size: 11px;
    margin-left: 0px;
}
.sc-footer-link {
    margin-left: 15px;
    color: white;
    text-transform: uppercase;
}
.sc-footer-link:hover {
    margin-left: 15px;
    color: white;
    text-transform: uppercase;
}
.sc-footer-link:last-child {
    margin-left: 15px;
}
#main-wrapper{
    
}
#main-content{
    margin-top: 20px;
}
.application-count-count{
    font-size: 22px;
    color: #505050;
    font-weight: 500;
}
th {
    font-size: 14px;
    font-weight: 500;
    color: #505050;
    white-space: nowrap;
}
td {
    font-size: 14px;
    font-weight: 500;
    color: #505050;
    white-space: nowrap;
}
#application-dropdown-toggle{
    color: #eeeeee !important;
}
#application-protection-password::placeholder {
    font-size: 11px !important;
}
#application-ssh-password::placeholder {
    font-size: 11px !important;
}
.signin-text{
    font-size: 10px;
    text-align: right;
}
.deploy-header {
    margin-bottom: 10px;
}
.modal-body {
    font-size: 14px;
}
.disabled {
    pointer-events: none;
}
.MuiButton-containedSecondary {
    background-color: #FF2400 !important;
}
.MuiButton-containedSecondary:hover {
    background-color: #FF2400 !important;
}
.MuiButton-root{
    font-size: 14px !important;
    padding-top: 8px !important;
    padding-bottom: 5px !important;
}
.MuiButton-root:hover{
    font-size: 14px !important;
    padding-top: 8px !important;
    padding-bottom: 5px !important;
}
#deploy-button {
    font-size: 14px !important;
}
@media (min-width: 575.98px) { 
    .application-view-title{
        font-size: 18px;
    }
    .application-view-url{
        font-size: 14px;
    }
    .application-view-resources{
        font-size: 13px;
    }
    .sc-pill{
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 4px 10px;
    }
 }
 @media (min-width: 767.98px) { 
    .application-view-title{
        font-size: 18px;
    }
    .application-view-url{
        font-size: 14px;
    }
    .application-view-resources{
        font-size: 12px;
    }
    .sc-pill{
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 4px 10px;
    }
  }
@media (min-width: 991.98px) { 
    .application-view-title{
        font-size: 18px;
    }
    .application-view-url{
        font-size: 13px;
    }
    .application-view-resources{
        font-size: 12px;
    }
    .sc-pill{
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 4px 10px;
    }
    .sc-footer-links {
        font-size: 11px;
        margin-left: auto;
    }
    .signin-text{
        font-size: 13px;
        text-align: left;
    }
    .deploy-header {
        margin-bottom: 30px;
    }
}

@media (min-width: 1199.98px) { 
    .application-view-title{
        font-size: 20px;
    }
    .application-view-url{
        font-size: 14px;
    }
    .application-view-resources{
        font-size: 13px;
    }
    .sc-pill{
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 5px 13px;
    }
}